import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { getHiddenAssistsIds } from '../../utils/general.util';
import { getNumberFormat } from '../../utils/getNumberFormat';
import {
  getMinimumInstallmentValue,
  getMonthNameByChar,
  PaymentFrequencyType
} from '../../utils/money.util';
import { getProductType } from '../../utils/products.utils';
import { ButtonSelect } from '../buttons/ButtonSelect';
import { ReadMore } from '../fragments/ReadMore';
import { ModalLoad } from '../modals/ModalLoad';

type CardsHomeForOneOrTwoProductProps = {
  id: string;
  title: string;
  subtitle: string;
  text: string;
  price: number;
  assistences: {
    id_servico: number;
    assist: string;
  }[];
  covers: {
    cover: string;
    value: string;
  }[];
  recommended: boolean;
  codigo_corretor: number;
  codigo_estrutura_venda: number;
  quotationDatas: any;
  quotationMin: number;
  quotationMax: number;
  corretagem: number;
  agenciamento: number;
  intermediacao?: number;
  initialValue: number;
  websiteId: string;
  websiteStatus: string;
  product_id: string;
  color: string;
  disabled?: boolean;
  product: any;
  redirectProdId: string | null;
};

const allowedValues = [
  10000, 20000, 30000, 40000, 50000, 70000, 100000, 150000, 200000, 250000,
  300000, 400000, 450000
];

const MAX_NUMBER_COVERAGES = 5;
const MAX_NUMBER_ASSIST = 3;

export const CardsHomeForOneOrTwoProduct = ({
  recommended,
  id,
  title,
  subtitle,
  price,
  text,
  covers,
  codigo_corretor,
  codigo_estrutura_venda,
  assistences,
  quotationDatas,
  quotationMin,
  quotationMax,
  websiteId,
  websiteStatus,
  product_id,
  initialValue,
  corretagem,
  agenciamento,
  intermediacao,
  color,
  disabled,
  product,
  redirectProdId
}: CardsHomeForOneOrTwoProductProps) => {
  const hiddenAssistsIds = getHiddenAssistsIds();

  const cardHomeRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const boxSideRight = useRef() as React.MutableRefObject<HTMLDivElement>;
  const boxSideLeft = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [showMoreCoverage, setShowMoreCoverage] = useState(false);
  const [showMoreAssist, setShowMoreAssist] = useState(false);

  const minAllowedIdx = allowedValues.indexOf(Number(quotationMin));
  const maxAllowedIdx = allowedValues.indexOf(Number(quotationMax));

  const [sliderValue, setSliderValue] = useState(initialValue);

  const [openModal, setOpenModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const websiteColor = color;

  const hasVariableCapital = quotationMin && quotationMax;
  const changeSlierAndPrice = (v: number) => {
    if (hasVariableCapital) {
      const valueToSet = allowedValues[v - 1 + minAllowedIdx];
      if (valueToSet) {
        setSliderValue(valueToSet);
      }
    }
  };

  return (
    <>
      <Box
        ref={cardHomeRef}
        m="0 0 10px 0 !important"
        maxW="1192px"
        w="98%"
        bg="#FFFFFF"
        borderRadius="10px"
        position="relative"
        textAlign="start"
        display="flex"
        justifyContent="space-between"
        boxShadow=" 0px 4px 10px rgba(115, 137, 169, 0.5)"
        borderLeft={`10px solid ${websiteColor}`}
      >
        <Box w="100%" p="30px 40px">
          <Text
            color="#323232"
            fontSize="2.5rem"
            lineHeight="3.5rem"
            fontWeight="700"
          >
            {subtitle}
          </Text>
          <Text
            color="#323232"
            fontSize="1.25rem"
            lineHeight="2rem"
            fontWeight="400"
            m="10px 0 0 0"
            wordBreak="break-word"
          >
            {text ? (
              <div className="content">
                <p style={{ display: 'inline', width: '100%' }}>{text}</p>
              </div>
            ) : (
              <div>
                <p style={{ display: 'inline', width: '100%' }}>-</p>
              </div>
            )}
          </Text>
          <HStack m="30px 0 0 0 !important" align="start">
            <Box
              w="100%"
              minH="154px"
              borderRight="1px solid #D2D2D2"
              ref={boxSideLeft}
              pr="29px"
            >
              <HStack w="100%">
                <Text>Indenização</Text>
                <ChevronDownIcon
                  cursor="pointer"
                  onClick={() => {
                    setShowMoreCoverage(!showMoreCoverage);
                  }}
                />
              </HStack>
              <Box w="100%">
                {covers.map((item, index) => {
                  if (showMoreCoverage || index < MAX_NUMBER_COVERAGES) {
                    return (
                      <Box
                        key={index}
                        mt="10px"
                        display="flex"
                        alignItems="start"
                      >
                        <CheckIcon
                          mr="12px"
                          w="22px"
                          h="22px"
                          borderRadius="3px"
                          p="2px"
                          color="#FFFFFF"
                          bg={websiteColor!}
                        />
                        <Box w="100%" display="flex" flexDirection="column">
                          <Text
                            color={'#323232'}
                            fontSize="0.875rem"
                            lineHeight="1.375rem"
                            fontWeight="400"
                          >
                            {item.cover}
                          </Text>
                          <Text
                            m="0 !important"
                            color={'#323232'}
                            fontSize="0.875rem"
                            lineHeight="1.375rem"
                            fontWeight="700"
                          >
                            {getNumberFormat(Number(item.value).toFixed(2))}
                          </Text>
                        </Box>
                      </Box>
                    );
                  }
                })}
              </Box>
            </Box>
            <Box
              w="100%"
              p="0 0 0 22px !important"
              display="flex"
              flexDirection="column"
              justifyContent="start"
              ref={boxSideRight}
            >
              <HStack w="100%">
                <Text>Assistências</Text>
                <ChevronDownIcon
                  cursor="pointer"
                  onClick={() => {
                    setShowMoreAssist(!showMoreAssist);
                  }}
                />
              </HStack>
              <Box w="100%">
                {assistences
                  .filter((ass) => !hiddenAssistsIds.includes(ass.id_servico))
                  .map((item, index) => {
                    if (showMoreAssist || index < MAX_NUMBER_ASSIST) {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="start"
                          justifyContent="start"
                          mt="10px"
                        >
                          <CheckIcon
                            mr="12px"
                            w="22px"
                            h="22px"
                            borderRadius="3px"
                            p="2px"
                            color="#FFFFFF"
                            bg={websiteColor!}
                          />
                          <Text
                            m="0 !important"
                            color={'#323232'}
                            fontSize="0.875rem"
                            lineHeight="1.375rem"
                            fontWeight="400"
                          >
                            {item.assist}
                          </Text>
                        </Box>
                      );
                    }
                  })}
              </Box>
            </Box>
          </HStack>
        </Box>
        <VStack
          w="100%"
          maxW="380px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bg="#F4F4F4"
          p="20px 40px 30px 40px"
        >
          <Box bg="#FFFFFF" borderRadius="15px" p="10px 20px">
            <Text
              color="#323232"
              fontWeight="400"
              m="0 !important"
              w="100%"
              textAlign="center"
            >
              A partir de:
            </Text>
            <Box
              w="100%"
              m="0 !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                color="#323232"
                fontSize="2.5rem"
                lineHeight="3.75rem"
                fontWeight="700"
              >
                {getNumberFormat(
                  getMinimumInstallmentValue(
                    price,
                    getProductType(Number(id))
                  ).installment?.toFixed(2)
                )}
              </Text>
              <Text
                color="#323232"
                fontSize="1.3rem"
                lineHeight="1.3rem"
                fontWeight="400"
              >
                &nbsp;/
                {getMonthNameByChar(
                  getMinimumInstallmentValue(price, getProductType(Number(id)))
                    .frequency as PaymentFrequencyType
                )}
              </Text>
            </Box>
          </Box>
          <Text
            m="30px 0 10px 0 !important"
            color={'#323232'}
            fontSize="0.875rem"
            lineHeight="1.375rem"
            fontWeight="400"
            w="100%"
            display={'none'}
          >
            Escolha o valor da indenização:
          </Text>
          <Stack
            w="100%"
            m="0 !important"
            justifyContent="center"
            display={'none'}
          >
            <Slider
              w="100%"
              id="slider"
              defaultValue={
                hasVariableCapital
                  ? minAllowedIdx -
                      (allowedValues.indexOf(Number(initialValue)) + 1) <
                    0
                    ? (minAllowedIdx -
                        allowedValues.indexOf(Number(initialValue))) *
                      -1
                    : minAllowedIdx -
                      (allowedValues.indexOf(Number(initialValue)) + 1)
                  : 10
              }
              step={1}
              isDisabled={!hasVariableCapital}
              min={1}
              max={hasVariableCapital ? maxAllowedIdx - minAllowedIdx + 1 : 10}
              colorScheme={websiteColor!}
              color={websiteColor!}
              onMouseEnter={() => setShowTooltip(true)}
              onChange={(v) => {
                changeSlierAndPrice(v);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack bg={websiteColor!} />
              </SliderTrack>
              <Tooltip
                hasArrow
                bg="#FFFFFF"
                color="#001E64"
                placement="top"
                isOpen={showTooltip}
                label={`${getNumberFormat(sliderValue.toFixed(2))}`}
              >
                <SliderThumb
                  bg={websiteColor ? websiteColor : '#FF5000'}
                  _disabled={{
                    color: websiteColor ? websiteColor : '#FF5000'
                  }}
                  _hover={{}}
                  _active={{}}
                  _focus={{
                    '&:focus': {
                      boxShadow: 'none !important'
                    }
                  }}
                />
              </Tooltip>
            </Slider>
            <Box m="0 !important" display="flex" justifyContent="space-between">
              {Boolean(hasVariableCapital) && (
                <>
                  <Text>{getNumberFormat(String(quotationMin))}</Text>
                  <Text>{getNumberFormat(String(quotationMax))}</Text>
                </>
              )}
              {!hasVariableCapital && (
                <>
                  <Text> R$ - </Text>
                  <Text>{getNumberFormat(sliderValue.toFixed(2))}</Text>
                </>
              )}
            </Box>
          </Stack>
          <Stack m="0 !important" w="100%" maxW="300px">
            <ButtonSelect
              quotationData={quotationDatas}
              idProduct={id}
              product_id={product_id}
              product={product}
              redirectProdId={redirectProdId}
              priceProduct={String(sliderValue)}
              productSave={setOpenModal}
              recommended={recommended}
              title={title}
              subtitle={subtitle}
              text={text}
              assist={assistences}
              cover={covers}
              codigo_corretor={codigo_corretor}
              codigo_estrutura_venda={codigo_estrutura_venda}
              buttonColor={websiteColor}
              disabled={disabled}
              corretagem={corretagem}
              agenciamento={agenciamento}
              intermediacao={intermediacao}
            />
          </Stack>
        </VStack>
      </Box>
      <ModalLoad isOpenModal={openModal} content={'Salvando produto...'} />
    </>
  );
};
